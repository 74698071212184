<div class="tile">
  <p *ngIf="message">{{message}}</p>
  <div *ngIf="!message">
    <div>
      {{info}}
    </div>
    <div class="btn-container">
      <button class="btn-action" (click)="onSubmit(true)">Confirm</button>
      <button class="btn-action" (click)="onSubmit(false)">Reject</button>
    </div>
  </div>
</div>
