import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vender-acknowledge',
  templateUrl: './vender-acknowledge.component.html',
  styleUrls: ['./vender-acknowledge.component.css']
})
export class VenderAcknowledgeComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private http: HttpClient) { }

  approvalMsg:string = "Thank you! Your response 'Approve' has been successfully registered.";
  errorMsg:string = "An error occurred while recording your response. Please try again later!";
  rejectedMsg:string = "Thank you! Your response 'Reject' has been successfully registered.";
  vendorInfo:string = "I have reviewed the vendor details and acknowledging vendor creation.";
  identityInfo:string ="I have reviewed the identity details and acknowledging identity creation.";
  message:string;
  token:string;
  info:string

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); 
        if(params.token == undefined || params.type == undefined){
           this.message = this.errorMsg;
         }
         else{
          this.token = params.token;
          this.info = params.type == "vendor"?this.vendorInfo:this.identityInfo;
          this.message = "Validating Request ...";
          this.validateRequest();
         }
      }
    );
  }

  onSubmit(val:boolean){
    this.message = "Submitting your response ...";
    let body = {
      token:this.token,
      state:val
    }
    this.http.post(environment.azureFunctionURL,body).subscribe((response:any)=>{
      this.message = response.message;
    });
  }

  validateRequest(){
    let body = {
      token:this.token,
      Validate:true
    }
    this.http.post(environment.azureFunctionURL,body).subscribe((response:any)=>{
      this.message = response.message;
    })
  }

}
